import React from "react"
import BackgroundImage from "gatsby-background-image/index"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

function CloudCard({ className, title, icon, children }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          tech: allFile(filter: { sourceInstanceName: { eq: "tech" } }) {
            edges {
              node {
                childImageSharp {
                  fluid(maxWidth: 300, quality: 75) {
                    originalName
                    ...GatsbyImageSharpFluid_noBase64
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <StyledBG
          preserveStackingContext={true}
          className={className}
          fluid={
            data.tech.edges.find(element => {
              let fname = element.node.childImageSharp.fluid.src
                .split("/")
                .pop()
              return fname === `${icon}.png`
            }).node.childImageSharp.fluid
          }
        >
          <p className="paragraph-2 ">{children}</p>
        </StyledBG>
      )}
    />
  )
}

const StyledBG = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-size: contain;
  &::before,
  &::after {
    opacity: 0.05 !important;
  }
`
export default CloudCard
