import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Card from "../components/Card"
import CloudCard from "../components/CloudCard"
import bg_exp from "../images/bg_exp.svg"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import GridBase from "@material-ui/core/Grid"

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`
const Expertise = ({ data }) => {
  return (
    <Layout>
      <div className="header subpage-bg">
        <div className="container">
          <div className="w-layout-grid hero-grid">
            <div className="hero-block">
              <h1 className="heading">
                Our Expertise
                <br />
              </h1>
              <p className="paragraph-large left">
                Years of certified experience, at your service.
                <br />
              </p>
            </div>
            <div className="hero-image-wrap-subpage">
              <img src={bg_exp} alt="" className="hero-image-subpage" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="content-section"
        style={{
          paddingBottom: "10px",
        }}
      >
        <h2 className="heading-2">Technology</h2>
        <br />
        <div className="container-4 w-container">
          <Grid
            container
            alignItems="stretch"
            spacing={4}
            className={"w-container"}
          >
            <Grid item xs={12} lg={4}>
              <CloudCard title="AWS" icon={"aws"}>
                A decade of experience on the biggest platform out there.
                <br />
                As official <b>AWS Partners</b>, our <b>AWS</b> certified cloud
                engineers deliver precise consulting and support.
              </CloudCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CloudCard title="Azure" icon={"azure"}>
                We've partnered with <b>Azure</b> to meet the surging demand for
                the Microsoft cloud.
                <br />
                Our <b>Azure</b> certified cloud engineers deliver the help you
                need when you need it.
              </CloudCard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CloudCard title="Kubernetes" icon={"kube"}>
                Certified on <b>Kubernetes</b>, we provide end-to-end solutions
                for your container orchestration journey.
              </CloudCard>
            </Grid>
          </Grid>
        </div>
      </div>
      <div
        className="content-section"
        style={{
          paddingBottom: "10px",
        }}
      >
        <h2 className="heading-2">Certifications</h2>
        <br />
        <Grid container alignItems="stretch" className={"w-container"}>
          {data.certs.edges.map(({ node, index }) => (
            <Grid item xs={6} md={3} lg={2} key={index}>
              <Img fluid={node.childImageSharp.fluid} alt={node.alt} />
            </Grid>
          ))}
        </Grid>
      </div>
      <div className="content-section">
        <h2 className="heading-2">Our added value</h2>
        <br />
        <Grid container alignItems="stretch" className={"w-container"}>
          <Grid item xs={12} lg={6}>
            <Card title="Born in the cloud" icon={10}>
              We've been laser focused on improving engineers synergy with the
              cloud ever since it became a thing, so that you can do more with
              less.
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card title="Built for resiliency" icon={3}>
              We build systems resilient by design with fault tolerance and
              self-healing in mind. Our deployments are scalable to meet your
              needs now and in the future.
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card title="Cost-efficiency driven" icon={13}>
              We've managed some of the biggest public cloud accounts out there
              and helped businesses save millions. We provide cost projections
              for each project along with recommendations on how to best make
              use of your resources.
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card title="Open-source Aficionados" icon={15}>
              We use community-built tools wherever possible and contribute back
              to the projects whenever we have the capability to. Tools we build
              to serve our global needs are open-sourced when possible.
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card title="Security by design" icon={1}>
              Our projects are built with security in mind from the ground up.
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card title="Automation first" icon={18}>
              Everything is built with automation in mind. All resources are
              written in a repeatable, idempotent way with changes audit
              built-in.
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card title="Making engineers lives easier" icon={16}>
              Breaking down silos doesn't have to mean burdening your software
              developers with tedious tasks. We strongly believe the quality of
              a product is directly correlated with the ease-of-use of the
              software build and delivery pipeline.
              <br />
              Whether it's DevOps, ChatOps or GitOps, we've done it and will
              provide you with the best culture fit for your needs.{" "}
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card title="Globally reachable" icon={7}>
              With points of presence in North America, EMEA and Asia, we have
              experience dealing with distributed teams.
              <br />
              Our engineers are reachable on any medium your business uses.
            </Card>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    certs: allFile(filter: { sourceInstanceName: { eq: "certs" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300, quality: 75) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default Expertise
